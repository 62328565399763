/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const ShriRamChandraMissionUSA = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation SRCM, USA";
    return `<gatsby_donation donationId = ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Shri Ram Chandra Mission, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Shri_Ram_Chandra_Mission,_USA", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="Shri Ram Chandra Mission USA -Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Shri Ram Chandra Mission USA</h3>}
    >
      <React.Fragment>
        <p sx={{ my: 3, fontSize: "24px" }}>
          Voluntary Donation – Options to donate
        </p>
        <Box
          sx={{ backgroundColor: "#e9f6f9", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Checks
          </h4>
          <small>
            Checks should be payable to “Shri Ram Chandra Mission” and mailing
            it to the following address
          </small>
          <p>
            Shri Ram Chandra Mission
            <br />
            P O Box 3413
            <br />
            Princeton NJ 08543
          </p>
        </Box>
        <Box
          sx={{ backgroundColor: "#e9f6f9", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Bank Transfer
          </h4>
          <small>
            You can also donate using money transfer from your bank directly to
            SRCM
          </small>
          <p>
            Account No.: 381047312593
            <br />
            Account Name: Shri Ram Chandra Mission
            <br />
            Routing Number: 021200339
            <br />
            Address: PO Box 3413, Princeton NJ 08543
            <br />
            Please send an email to{" "}
            <a href="mailto:us.treasurer@srcm.org">
              us.treasurer@srcm.org
            </a>{" "}
            after the transfer is complete.
          </p>
        </Box>
        <Box
          sx={{ backgroundColor: "#f2fafc", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Wire Transfer
          </h4>
          <small>
            You can also donate using wire transfer from your bank directly to
            SRCM
          </small>
          <p>
            Account No.: 381047312593
            <br />
            Account Name: Shri Ram Chandra Mission
            <br />
            Routing Number: 026009593
            <br />
            Address: PO Box 3413, Princeton NJ 08543
            <br />
            Please send an email to{" "}
            <a href="mailto:us.treasurer@srcm.org">
              us.treasurer@srcm.org
            </a>{" "}
            after the transfer is complete.
          </p>
        </Box>
        <Box
          sx={{ backgroundColor: "#e9f6f9", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Stocks
          </h4>
          <small>
            Please send stock to Shri Ram Chandra Mission&apos;s account at
            Merrill Lynch. DTC and
            <br />
            Account info is as follows:
          </small>
          <p>
            Acct Title: Shri Ram Chandra Mission
            <br />
            Account No.: 208-02926
            <br />
            Receiving Firm: Merrill Lynch Wealth Management
            <br />
            DTC#: 8862
          </p>
        </Box>
        <Box
          sx={{ backgroundColor: "#f2fafc", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Notify SRCM of Gift
          </h4>
          <small>
            For SRCM to identify your gift and send a tax receipt, kindly send
            an email to &nbsp;
            <a href="mailto:us.treasurer@srcm.org">us.treasurer@srcm.org</a> and
            &nbsp;<a href="mailto:us.accounts@srcm.org">us.accounts@srcm.org</a>{" "}
            with the gift details requested below:
          </small>
          <p>
            Full name of donor:
            <br />
            Email of donor:
            <br />
            Telephone of donor:
            <br />
            Date of transfer:
            <br />
            Name of security:
            <br />
            Number of shares transferred:
          </p>
        </Box>
        <Box
          sx={{ backgroundColor: "#f2fafc", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Online Donations (Credit Card)
          </h4>
          <div sx={{ width: "165px" }}>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(15, "Donate Now")}
            />
          </div>
        </Box>
        {/* <Box
          sx={{ backgroundColor: "#f2fafc", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            STOCK TRANSFER TO SRCM
          </h4>
          <small>
            Instruct institution where securities are held to transfer the
            shares to Shri Ram Chandra Mission&apos;s account at Merrill Lynch,
            DTC and Account Numbers are as follows:
          </small>
          <p>
            Account No.: 8UN-02004
            <br />
            Title: Shri Ram Chandra Mission
            <br />
            Receiving Firm: Merrill Lynch
            <br />
            DTC#: 8862
          </p>
        </Box> */}
        {/* <Box
          sx={{ backgroundColor: "#e9f6f9", padding: "15px", margin: "20px 0" }}
        >
          <h4
            sx={{
              borderBottom: "solid 1px #c9dfe4",
              paddingBottom: "8px",
              marginBottom: "10px",
            }}
          >
            Notify SRCM of Gift
          </h4>
          <small>
            SRCM to identify your gift and send a tax receipt you will need to
            email{" "}
            <a href="mailto:us.treasurer@srcm.org">us.treasurer@srcm.org</a>{" "}
            with the gift details requested below
          </small>
          <p>
            Full name of donor:
            <br />
            Email of donor:
            <br />
            Telephone of donor:
            <br />
            Date of transfer:
            <br />
            Name of security:
            <br />
            Number of shares transferred:
          </p>
        </Box> */}
        <small>
          If you need any additional information please send an email to Dheeraj
          Tandon, SRCM USA Treasurer at{" "}
          <a href="mailto:us.treasurer@srcm.org ">us.treasurer@srcm.org </a> or
          you may call (917) 497 4628.
        </small>
      </React.Fragment>
    </LayoutHome>
  );
};

export default ShriRamChandraMissionUSA;
